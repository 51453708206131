<template>
  <div>
    <Button
      :text="statusText"
      :outlined="outlinedBtn"
      :horizontal-padding="horizontalPaddingBtn"
      :aria-label="statusText"
      @event="dialog = true"
    />
    <v-dialog v-model="dialog" max-width="650px" max-height="500">
      <v-card class="px-10 px-sm-16 pb-6 pt-12 pt-sm-8">
        <div class="mt-2 d-flex justify-center">
          <v-card-title class="text-h2 font-weight-bold mb-5 pa-0">
            {{ statusText }}
            <v-btn
              icon
              :ripple="false"
              absolute
              right
              top
              class="btn-background-transparent hover-close-btn"
              @click="closeModal"
            >
              <v-icon large color="secondary">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </div>
        <v-card-text class="pa-0 text-h4 black--text">
          <div class="mb-2 mb-sm-6">Solicitud #{{ requestNumber }}</div>
          <div class="mb-6 d-flex align-center">
            <v-icon class="mr-1" size="15" :color="colorStatus">
              mdi-brightness-1
            </v-icon>
            {{ requestStatus }}
          </div>
          <v-row class="mb-6">
            <v-col cols="12" sm="6">
              <div class="font-weight-bold mb-0 mb-sm-2">Proveedor</div>
              <div>{{ requestProvider }}</div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="font-weight-bold mb-0 mb-sm-2">Categoría</div>
              <div>{{ requestCategory }}</div></v-col
            >
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex flex-column align-center pa-0">
          <p
            v-if="requestStatus === 'Recibida' || requestStatus === 'Pendiente'"
            class="font-italic text-h4 text-center text--disabled"
          >
            Al cancelar la solicitud se eliminará de tu perfil y ya no la podrás
            consultar.
          </p>
          <p
            v-else-if="requestStatus === 'Rechazada'"
            class="font-italic text-h4 text-center text--disabled"
          >
            Las solicitudes rechazadas con antigüedad de 6 meses se eliminan
            automáticamente
          </p>
          <Button
            :text="
              requestStatus === 'Rechazada'
                ? 'Eliminar solicitud'
                : 'Cancelar solicitud'
            "
            block
            :disabled="!dialog"
            :aria-label="
              requestStatus === 'Rechazada'
                ? 'Eliminar solicitud'
                : 'Cancelar solicitud'
            "
            @event="handleUpdateClick()"
          />
          <a
            class="mt-5 mb-2 justify-center text-h4 font-weight-bold text-decoration-underline"
            style="color: inherit"
            @click="closeModal"
          >
            Cancelar
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";

export default {
  name: "ModalEraseRequest",
  components: {
    Button,
  },
  props: {
    outlinedBtn: {
      type: Boolean,
      default: false,
    },
    horizontalPaddingBtn: {
      type: Number,
      default: 12,
    },
    requestNumber: {
      type: String,
      default: "",
    },
    colorStatus: {
      type: String,
      default: "",
    },
    requestStatus: {
      type: String,
      default: "",
    },
    requestProvider: {
      type: String,
      default: "",
    },
    requestCategory: {
      type: String,
      default: "",
    },
    statusText: {
      type: String,
      default: "",
    },
    updateRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeModal() {
      this.dialog = false;
    },
    async handleUpdateClick() {
      await this.updateRequest();
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.btn-background-transparent::before {
  background-color: transparent !important;
}

.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}

.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}

.hover-close-btn:hover .v-icon {
  transform: scale(1.1);
}
</style>
