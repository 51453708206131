<template>
  <div class="py-9">
    <div class="px-7 my-3">
      <div class="mb-10">
        <div class="mb-5 text-h3 font-weight-black">Detalles de solicitud</div>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>Ciudad del evento</span>
          </v-col>
          <v-col cols="12" md="8">
            <span>
              {{ requestDetails.eventCity }}, {{ requestDetails.eventState }}
            </span>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>Dirección</span>
          </v-col>
          <v-col cols="12" md="8">
            <span>{{ requestDetails.eventAddress }}</span>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>Fecha del evento</span>
          </v-col>
          <v-col cols="12" md="8">
            <span>{{ dateFormater(requestDetails.eventDate) }}</span>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>Hora</span>
          </v-col>
          <v-col cols="12" md="8">
            <span>{{ hourFormatter(requestDetails.eventDate) }}</span>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>Tipo de evento</span>
          </v-col>
          <v-col cols="12" md="8">
            <span>{{ requestDetails.eventType }}</span>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>Número de invitados</span>
          </v-col>
          <v-col cols="12" md="8">
            <span>{{ requestDetails.peopleQuantity }}</span>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>Comentarios</span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="text-pre-line">
              {{ requestDetails.clientComment }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="mb-12">
        <div class="mb-2 text-h3 font-weight-black">Motivo de rechazo</div>
        <v-row class="align-center mt-3 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>Comentario de proveedor</span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="text-pre-line">
              {{
                requestDetails.providerComment
                  ? requestDetails.providerComment
                  : "No se han proporcionado comentarios."
              }}
            </span>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  convertUnixToDDMMYYYY,
  convertUnixToHourMinute,
} from "@/Utils/dateConverter.js";

export default {
  name: "DeniedRequest",
  props: {
    requestDetails: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    dateFormater(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    hourFormatter(unixTime) {
      return convertUnixToHourMinute(unixTime);
    },
  },
};
</script>

<style scoped>
.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}
</style>
