<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '30px' }
          : { 'padding-bottom': '40px' },
      ]"
    >
      <v-container v-if="quotation && !loading" class="px-10 px-md-auto">
        <div class="d-flex flex-column flex-md-row">
          <div>
            <div class="text-h2 text-sm-h1 font-weight-black mb-2">
              Solicitud #{{ this.$route.params.id }}
            </div>
            <div class="d-flex align-center text-h3 font-weight-regular">
              <v-icon
                class="mr-2"
                :color="getColor(quotation.quotationRequest.status)"
              >
                mdi-brightness-1
              </v-icon>
              {{ getStatusName(quotation.quotationRequest.status) }}
            </div>
            <div class="d-flex flex-column flex-wrap flex-lg-row mt-2 ml-1">
              <div
                class="d-flex flex-column flex-sm-row text-h4 font-weight-regular mr-md-10"
              >
                <div class="mr-1">Fecha de solicitud:</div>
                <div>
                  {{
                    convertUnixToDate(
                      requestDate
                    ),
                  }}
                </div>
              </div>
              <div
                v-if="quotation.quotationRequest.status === 'approved'"
                class="d-flex flex-column flex-sm-row text-h4 font-weight-regular"
              >
                <div class="mr-1">Solicitud válida hasta:</div>
                <div>
                  {{
                    convertUnixToDate(quotation.quotationRequest.validUntil)
                  }}. Hasta las 23:59 (horario CST)
                </div>
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="mt-7 d-flex flex-column flex-sm-row">
            <div
              v-if="
                quotation.quotationRequest.status === 'rejected' ||
                quotation.quotationRequest.status === 'pending' ||
                quotation.quotationRequest.status === 'approved'
              "
            >
              <ModalEraseRequest
                :outlined-btn="quotation.quotationRequest.status === 'approved'"
                :horizontal-padding-btn="
                  quotation.quotationRequest.status === 'approved' ? 6 : 12
                "
                :request-number="this.$route.params.id"
                :color-status="getColor(quotation.quotationRequest.status)"
                :request-status="
                  getStatusName(quotation.quotationRequest.status)
                "
                :status-text="getStatusText(quotation.quotationRequest.status)"
                :request-provider="quotation.provider.name"
                :request-category="quotation.quotationRequest.category"
                :cancel-loading="cancelLoading"
                :update-request="cancelQuotationInfo"
              />
            </div>
            <!-- <div
              v-if="
                quotation.quotationRequest.status === 'approved'
              "
              <Button
                text="Firmar contrato"
                class="mt-4 mt-sm-0 ml-sm-4"
                aria-label="Firmar contrato"
              />
            </div> -->
            <div v-if="quotation.quotationRequest.status === 'approved'">
              <Button
                text="Pagar servicio"
                class="mt-4 mt-sm-0 ml-sm-4"
                aria-label="Ir al pago del servicio"
                :url="getPaymentRoute.name"
                :url-params="getPaymentRoute.params"
              />
            </div>
          </div>
        </div>
        <Button
          text="Volver"
          pill
          outlined
          :height="43"
          prepend-icon="fa-caret-left"
          class="my-14"
          aria-label="Volver a lista de solicitudes"
          url="UserRequests"
        />
        <v-row>
          <v-col cols="12" md="6">
            <v-card
              elevation="0"
              class="mx-1 pa-7 text-h4 rounded-lg blue-border"
            >
              <div class="pb-1 text-h3 font-weight-black">
                Información del cliente
              </div>
              <div class="mt-4">
                {{ quotation.user.name }}
              </div>
              <div class="mt-4">
                {{ quotation.user.preferedName }}
              </div>

              <div class="mt-4">
                {{ quotation.quotationRequest.requesterCity }},
                {{ quotation.quotationRequest.requesterState }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card
              elevation="0"
              class="mx-1 pa-7 text-h4 rounded-lg blue-border"
            >
              <div class="pb-1 text-h3 font-weight-black">
                Información del proveedor
              </div>
              <div class="mt-4">
                <router-link
                  class="black--text text-decoration-underline"
                  :to="getProviderRoute"
                >
                  {{ quotation.provider.name }}
                </router-link>
              </div>
              <div class="mt-4">
                <router-link
                  class="black--text text-decoration-underline"
                  :to="getServiceRoute"
                >
                  {{ quotation.service.name }}
                </router-link>
              </div>
              <div class="mt-4">
                {{ providerAddress }}
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div>
          <ReceivedRequest
            v-if="quotation.quotationRequest.status === 'approved'"
            :request-details="quotation.quotationRequest"
            :service-deadline-data="quotation.service.paymentDeadline"
            :edit-mode="editMode"
            :requested-service="quotation.service.name"
            :payment-provider="quotation.quotationRequest.paymentProvider"
            :payment-zaturna="quotation.quotationRequest.paymentZaturna"
            :total-payment="quotation.quotationRequest.totalPayment"
            :payment-advance="quotation.service.paymentAdvance"
            :payment-days="quotation.service.validUntil"
            @changeEditMode="handleEditMode"
          />
          <PendingRequest
            v-else-if="quotation.quotationRequest.status === 'pending'"
            :request-details="quotation.quotationRequest"
          />
          <DeniedRequest
            v-else-if="quotation.quotationRequest.status === 'rejected'"
            :request-details="quotation.quotationRequest"
          />
        </div>
      </v-container>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
    <ModalLoading :dialog="cancelLoading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import Button from "@/components/Shared/Button.vue";
import ReceivedRequest from "@/components/User/MyRequestsDetails/ReceivedRequest.vue";
import DeniedRequest from "@/components/User/MyRequestsDetails/DeniedRequest.vue";
import ModalEraseRequest from "@/components/User/MyRequestsDetails/ModalEraseRequest.vue";
import PendingRequest from "@/components/User/MyRequestsDetails/PendingRequest.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import { GET_QUOTATION_DATA } from "@/graphql/queries";
import { UPDATE_QUOTATION_REQUEST } from "@/graphql/mutations";
import { useMutation, useQuery } from "@/graphql/index";
import {
  convertUnixToDate,
  convertDateToCstTimeZoneUnix,
} from "@/Utils/dateConverter.js";
import { formatProviderAddress } from "../../Utils/Utils";

export default {
  name: "RequestDetails",
  components: {
    Navbar,
    UserNavbar,
    Button,
    SearchBar,
    ReceivedRequest,
    DeniedRequest,
    ModalEraseRequest,
    PendingRequest,
    InlineLoading,
    ModalLoading,
  },
  data: () => ({
    quotation: {
      quotationRequest: {
        status: "",
      },
      provider: {
        name: "",
      },
      service: {
        name: "",
      },
      user: {
        name: "",
      },
    },
    editMode: false,
    loading: true,
    cancelLoading: false,
    quotationFetching: false,
    quotationFetched: false,
  }),
  computed: {
    ...mapState({
      user(state) {
        return state.user;
      },
    }),
    providerAddress() {
      return formatProviderAddress(this.quotation.provider);
    },
    requestDate() {
      return convertDateToCstTimeZoneUnix(
        this.quotation.quotationRequest.insertedAt
      );
    },
    getProviderRoute() {
      return {
        name: "UserProvider",
        params: {
          id: this.quotation.provider.id,
        },
      };
    },
    getServiceRoute() {
      return {
        name: "UserService",
        params: {
          id: this.quotation.service.id,
        },
      };
    },
    getPaymentRoute() {
      return {
        name: "UserPayment",
        params: {
          id: this.$route.params.id,
        },
      };
    },
  },
  watch: {
    user: {
      async handler(user) {
        if (!user.id) return;
        if (!this.quotationFetching && !this.quotationFetched) {
          this.quotationFetching = true;
          await this.fetchQuotationRequest();
          this.quotationFetched = true;
          this.quotationFetching = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchQuotationRequest() {
      const props = {
        quotationId: parseInt(this.$route.params.id),
        clientId: parseInt(this.user.id),
        route: "user",
      };

      try {
        const { data, errors } = await useQuery(GET_QUOTATION_DATA, props);

        if (errors) throw errors;

        this.quotation = data.quotation;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "UserRequests" });
      }
    },
    async cancelQuotationInfo() {
      this.cancelLoading = true;
      const unixNow = Math.floor(Date.now() / 1000).toString();
      const { data, errors } = await useMutation(UPDATE_QUOTATION_REQUEST, {
        quotationRequestId: parseInt(this.$route.params.id),
        status: "cancelled",
        statusUpdated: unixNow,
      });
      if (data) {
        this.quotation.quotationRequest = data.updateQuotationRequest;
        this.$router.push({ name: "UserRequests" });
        this.$store.dispatch("handleAlert", {
          type: "success",
          message: "Su solicitud se ha cancelado correctamente",
        });
      } else if (errors) {
        this.$store.dispatch("handleAlert", {
          type: "error",
          message: "No se ha podido cancelar correctamente su solicitud",
        });
      }
      this.cancelLoading = false;
    },
    getColor(status) {
      if (status === "pending") {
        return "warning";
      } else if (status === "rejected") {
        return "error";
      } else if (status === "approved") {
        return "success";
      } else {
        return "transparent";
      }
    },
    getStatusText(status) {
      if (status === "rejected") {
        return "Eliminar solicitud";
      } else if (status === "approved" || status === "pending") {
        return "Cancelar solicitud";
      }
    },
    getStatusName(status) {
      if (status === "pending") {
        return "Pendiente";
      } else if (status === "rejected") {
        return "Rechazada";
      } else if (status === "approved") {
        return "Recibida";
      }
    },
    handleEditMode() {
      this.editMode = !this.editMode;
    },
    convertUnixToDate(unixDate) {
      return convertUnixToDate(unixDate);
    },
  },
};
</script>

<style scoped>
.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}

.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}

.blue-border {
  border: 1px solid var(--v-secondary-base);
}
</style>
