<template>
  <div>
    <div class="py-9">
      <div class="my-3">
        <div v-if="editMode === false">
          <div class="mb-10 mx-7">
            <div class="mb-5 text-h3 font-weight-black">
              Detalles de solicitud
            </div>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Ciudad del evento</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>
                  {{ requestDetails.eventCity }},
                  {{ requestDetails.eventState }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Dirección</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ requestDetails.eventAddress }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Fecha del evento</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ dateFormater(requestDetails.eventDate) }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Hora</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ hourFormatter(requestDetails.eventDate) }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Tipo de evento</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ requestDetails.eventType }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Número de invitados</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ requestDetails.peopleQuantity }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Comentarios</span>
              </v-col>
              <v-col cols="12" md="8">
                <span class="text-pre-line">
                  {{
                    requestDetails.clientComment
                      ? requestDetails.clientComment
                      : "No se han proporcionado comentarios."
                  }}
                </span>
              </v-col>
            </v-row>
          </div>
          <div class="mb-12 px-7">
            <div class="mb-5 text-h3 font-weight-black">
              Desglose de servicio solicitado
            </div>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Tipo de servicio</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ requestedService }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Costo de servicio (IVA incluido)</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ numberToCurrency(paymentProvider) }} MXN</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Comisión Zaturna (5%)</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ numberToCurrency(paymentZaturna) }} MXN</span>
              </v-col>
            </v-row>
            <v-row
              class="align-center font-weight-bold mt-4 text-h4"
              no-gutters
            >
              <v-col cols="12" md="4">
                <span>Total a pagar</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ numberToCurrency(totalPayment) }} MXN</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Cotización de servicio</span>
              </v-col>
              <v-col cols="12" md="4">
                <Button
                  text="Ver"
                  class="my-2 my-md-4"
                  aria-label="Descargar cotización de servicio"
                  download
                  is-external
                  :url="requestDetails.quotationUrl"
                />
                <Button
                  text="Editar solicitud"
                  outlined
                  :horizontal-padding="6"
                  class="mt-3 mt-sm-0 ml-sm-3"
                  aria-label="Cambiar a modo edición de solicitud"
                  @event="changeEditMode()"
                />
              </v-col>
              <v-col cols="12" md="4">
                <span class="text-h4 font-italic">
                  Si no estás conforme con la solicitud, puedes editar y pedir
                  cambios al proveedor.
                </span>
              </v-col>
            </v-row>
            <v-row
              v-if="requestDetails.requestExtraDocument.length > 0"
              class="align-center mt-4 text-h4"
              no-gutters
            >
              <v-col cols="12" md="4">
                <span>Archivo adicional</span>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  class="text-decoration-underline font-weight-bold"
                  style="color: inherit"
                  :href="requestDetails.requestExtraDocument[0].url"
                  download
                >
                  Archivo
                </a>
              </v-col>
            </v-row>
            <v-row class="align-center mt-7 text-h4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Comentarios del proveedor</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span class="text-pre-line">
                  {{
                    requestDetails.providerComment
                      ? requestDetails.providerComment
                      : "No se han proporcionado comentarios."
                  }}
                </span>
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="editMode === true">
          <div class="px-7 btn-background-purple">
            <ReceivedRequestEdit
              :service-deadline-data="serviceDeadlineData"
              :event-data="requestDetails"
              :event-type="eventTypes"
              @changeEditMode="changeEditMode"
            />
          </div>
          <div class="my-10 px-7">
            <div class="mb-5 text-h6 font-weight-black">
              Desglose de servicio solicitado
            </div>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Tipo de servicio</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ requestedService }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Costo de servicio (IVA incluido)</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ numberToCurrency(paymentProvider) }} MXN</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Comisión Zaturna (5%)</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ numberToCurrency(paymentZaturna) }} MXN</span>
              </v-col>
            </v-row>
            <v-row
              class="align-center font-weight-bold mt-4 text-h4"
              no-gutters
            >
              <v-col cols="12" md="4">
                <span>Total a pagar</span>
              </v-col>
              <v-col cols="12" md="8">
                <span>{{ numberToCurrency(totalPayment) }} MXN</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" md="4">
                <span>Cotización de servicio</span>
              </v-col>
              <v-col cols="12" md="8">
                <Button
                  text="Ver"
                  class="my-2 my-md-4"
                  aria-label="Descargar cotización de servicio"
                  download
                  is-external
                  :url="requestDetails.quotationUrl"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="requestDetails.requestExtraDocument.length > 0"
              class="align-center mt-4 text-h4"
              no-gutters
            >
              <v-col cols="12" md="4">
                <span>Archivo adicional</span>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  class="text-decoration-underline text-h4 font-weight-bold"
                  style="color: inherit"
                  :href="requestDetails.requestExtraDocument[0].url"
                  download
                >
                  Archivo
                </a>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <div class="px-7 my-8 my-md-3">
        <div class="mb-5 text-h3 font-weight-black">Plan de pagos</div>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>
              Anticipo
              {{ paymentAdvance }}%
            </span>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" md="4">
            <span>
              Liquidación
              <span
                v-if="
                  dateDifference(
                    requestDetails.eventDate,
                    requestDetails.paymentDeadline
                  ) == 0
                "
              >
                el día del evento
              </span>
              <span v-else>
                {{
                  dateDifference(
                    requestDetails.eventDate,
                    requestDetails.paymentDeadline
                  )
                }}
                {{
                  dateDifference(
                    requestDetails.eventDate,
                    requestDetails.paymentDeadline
                  ) == 1
                    ? "día"
                    : "días "
                }}
                antes del evento
              </span>
            </span>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <span class="font-italic">
            Proveedor acepta
            <a class="text-decoration-underline" style="color: inherit">
              Política de Cancelación
            </a>
          </span>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import ReceivedRequestEdit from "./ReceivedRequestEdit.vue";
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import {
  convertUnixToDDMMYYYY,
  convertUnixToHourMinute,
  dateDifference,
} from "@/Utils/dateConverter.js";

export default {
  name: "ReceivedRequest",
  components: { Button, ReceivedRequestEdit },
  props: {
    requestedService: {
      type: String,
      default: "",
    },
    paymentProvider: {
      type: Number,
      default: 0,
    },
    paymentZaturna: {
      type: Number,
      default: 0,
    },
    totalPayment: {
      type: Number,
      default: 0,
    },
    paymentAdvance: {
      type: Number,
      default: 0,
    },
    paymentDays: {
      type: String,
      default: "",
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    requestDetails: {
      type: Object,
      default: function () {
        return [];
      },
    },
    eventTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },
    serviceDeadlineData: {
      type: String,
      default: "",
    },
  },
  methods: {
    dateFormater(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },

    changeEditMode() {
      this.$emit("changeEditMode");
    },

    editColor(edit) {
      if (edit === false) {
        return "bg-service-section";
      } else if (edit === true) {
        return "transparent";
      }
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
    hourFormatter(unixTime) {
      return convertUnixToHourMinute(unixTime);
    },
    dateDifference(unixTimeOne, unixTimeTwo) {
      return dateDifference(unixTimeOne, unixTimeTwo);
    },
  },
};
</script>

<style scoped>
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: black !important;
}
.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}

.btn-background-purple {
  background-color: rgba(98, 37, 130, 0.1) !important;
}
</style>
