<template>
  <div class="py-8">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submitInfo"
    >
      <div class="mb-1">
        <div class="mb-5 text-h3 font-weight-black">Detalles de solicitud</div>
        <v-row class="align-baseline" no-gutters>
          <v-col cols="12" sm="3" md="3">
            <span class="mr-1">Estado del evento</span>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-autocomplete
              v-model="eventData.eventState"
              :value="eventData.eventState"
              :items="states"
              no-data-text="Estado no encontrado"
              outlined
              dense
              color="secondary"
              item-color="secondary"
              autocomplete="null"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              validate-on-blur
              :rules="stateRulesSelect"
              class="v-input--is-focused secondary--text rounded-lg"
              @change="eventStateUpdated"
            />
          </v-col>
        </v-row>
        <v-row class="align-baseline" no-gutters>
          <v-col cols="12" sm="3" md="3">
            <span class="mr-1">Ciudad del evento</span>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-autocomplete
              v-model="eventData.eventCity"
              :items="citySelect"
              :no-data-text="
                eventData.eventState
                  ? 'Ciudad no encontrada'
                  : 'Seleccione un estado'
              "
              outlined
              dense
              color="secondary"
              item-color="secondary"
              autocomplete="null"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              validate-on-blur
              :rules="cityRulesSelect"
              class="v-input--is-focused secondary--text rounded-lg"
              :value="eventData.eventCity"
            />
          </v-col>
        </v-row>
        <v-row class="align-baseline" no-gutters>
          <v-col cols="12" sm="3" md="3">
            <span class="mr-1">Dirección</span>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-text-field
              v-model="eventData.eventAddress"
              outlined
              dense
              class="v-input--is-focused secondary--text rounded-lg"
              validate-on-blur
              :rules="fillInput"
              maxlength="255"
              :value="eventData.eventAddress"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-baseline" no-gutters>
          <v-col cols="12" sm="3" md="3">
            <span class="mr-1">Fecha del evento</span>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDate"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  class="v-input--is-focused secondary--text rounded-lg"
                  placeholder="Fecha del evento"
                  validate-on-blur
                  :rules="fillInput"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :value="datePickerFormattedDate"
                color="secondary"
                no-title
                prev-icon="fa-chevron-left secondary--text"
                next-icon="fa-chevron-right secondary--text"
                show-adjacent-months
                :min="newUnixDay"
                @input="inputHandler"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="align-baseline" no-gutters>
          <v-col cols="12" sm="3" md="3">
            <span class="mr-1">Hora</span>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="choosenTime"
                  v-mask="'##:##'"
                  validate-on-blur
                  :rules="timeRules"
                  type="text"
                  outlined
                  dense
                  class="v-input--is-focused secondary--text rounded-lg"
                  @input="inputTimeHandler"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="choosenDayTime"
                  :items="timeFrame"
                  placeholder="AM"
                  single-line
                  outlined
                  solo
                  dense
                  flat
                  required
                  item-color="secondary"
                  color="secondary"
                  background-color="transparent"
                  :menu-props="{
                    bottom: true,
                    closeOnContentClick: true,
                    allowOverflow: true,
                    offsetY: true,
                    rounded: 'md',
                    transition: 'slide-y-transition',
                  }"
                  append-icon="fa-caret-down secondary--text"
                  hide-details="auto"
                  validate-on-blur
                  :rules="fillInput"
                  class="mb-3 mb-md-6 rounded-lg v-input--is-focused secondary--text text-subtitle-1"
                  @input="inputDayTimeHandler"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="align-baseline" no-gutters>
          <v-col cols="12" sm="3" md="3">
            <span class="mr-1">Tipo de evento</span>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-text-field
              v-model="eventData.eventType"
              outlined
              dense
              class="v-input--is-focused secondary--text rounded-lg"
              validate-on-blur
              maxlength="40"
              :rules="fillInput"
              :value="eventData.eventType"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-baseline" no-gutters>
          <v-col cols="12" sm="3" md="3">
            <span class="mr-1">Número de invitados</span>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-text-field
              v-model="eventData.peopleQuantity"
              outlined
              dense
              pattern="^[0-9]+"
              type="number"
              min="0"
              step="1"
              class="v-input--is-focused secondary--text rounded-lg"
              validate-on-blur
              :rules="quantityInput"
              :value="eventData.peopleQuantity"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-baseline" no-gutters>
          <v-col cols="12" sm="3" md="3">
            <span class="mr-1">Comentarios</span>
          </v-col>
          <v-col cols="12" sm="9" md="6">
            <v-textarea
              v-model="eventData.clientComment"
              outlined
              dense
              class="v-input--is-focused secondary--text rounded-lg"
              :value="eventData.clientComment"
              maxlength="255"
              counter="255"
              :rules="optionalTextAreaRules"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
      <v-row no-gutters>
        <v-col cols="12" sm="3" md="3"> </v-col>
        <v-col cols="12" sm="9" md="6">
          <div class="d-flex flex-column flex-sm-row justify-end">
            <Button
              text="Cancelar"
              outlined
              :block="$vuetify.breakpoint.xs"
              :horizontal-padding="6"
              :disabled="loading"
              class="mb-4 mb-sm-0 mr-2"
              aria-label="Cancelar"
              @event="changeEditMode()"
            />
            <Button
              text="Enviar cambios"
              :block="$vuetify.breakpoint.xs"
              :disabled="loading"
              type="submit"
              aria-label="Enviar cambios"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
    <ModalLoading :dialog="loading" />
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import {
  quantityInput,
  fillInput,
  stateRulesSelect,
  cityRulesSelect,
  timeRules,
  optionalTextAreaRules,
} from "@/Utils/rules";
import VueMask from "v-mask";
import Vue from "vue";
import {
  unixToHourMinuteEdit,
  convertUnixToAmPm,
  convertDateToUnix,
  convertUnixToYYYYMMDD,
  convertUnixToDDMMYYYY,
} from "@/Utils/dateConverter.js";
import { useMutation } from "@/graphql/index";
import { states, getCitiesByState } from "@/Utils/locations.js";
import { UPDATE_QUOTATION_REQUEST } from "@/graphql/mutations";
import moment from "moment";

Vue.use(VueMask);
export default {
  name: "ReceivedRequestEdit",
  components: { Button, ModalLoading },
  props: {
    eventData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    serviceDeadlineData: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    time: null,
    choosenDate: null,
    choosenTime: null,
    choosenDayTime: null,
    menu: false,
    loading: false,
    valid: false,
    unixTenYears: 315360000000,
    timeFrame: ["AM", "PM"],
    dayTime: "",
    inputHours: "",
    stateRulesSelect: stateRulesSelect,
    cityRulesSelect: cityRulesSelect,
    fillInput: fillInput,
    timeRules: timeRules,
    quantityInput: quantityInput,
    optionalTextAreaRules: optionalTextAreaRules,
    states: states,
    dataBeforeEdit: {},
    newUnixDay: "",
    secondsInDay: 86400,
    servicePaymentDeadline: "",
  }),

  computed: {
    formattedDate() {
      return this.convertUnixToDDMMYYYY(this.choosenDate);
    },
    datePickerFormattedDate() {
      return this.convertUnixToYYYYMMDD(this.choosenDate);
    },
    filterStatus() {
      return this.events.filter((event) => {
        const eventStatus = event.status.toLowerCase();
        let buttonValue = "";
        if (this.filterResult === 1) {
          buttonValue = "pending";
        } else if (this.filterResult === 2) {
          buttonValue = "approved";
        } else if (this.filterResult === 3) {
          buttonValue = "denied";
        }
        return eventStatus.includes(buttonValue);
      });
    },
    citySelect() {
      return getCitiesByState(this.eventData.eventState);
    },
  },

  created() {
    this.dataBeforeEdit = Object.assign({}, this.eventData);
    this.choosenDate = this.eventData.eventDate;
    this.choosenTime = this.hourFormatter(this.eventData.eventDate);
    this.choosenDayTime = this.timeClockFormater(this.eventData.eventDate);
    this.servicePaymentDeadline = this.serviceDeadlineData;
    this.dateUnixToString();
  },

  methods: {
    inputHandler(inputDate) {
      if (typeof inputDate === "string");
      inputDate = moment(inputDate);
      this.menu = false;
      let newDate = new Date(inputDate);
      this.choosenDate = newDate.getTime() / 1000;
    },
    inputTimeHandler(inputTime) {
      this.choosenTime = inputTime;
    },
    inputDayTimeHandler(inputDayTime) {
      this.choosenDayTime = inputDayTime;
    },
    changeEditMode() {
      Object.assign(this.eventData, this.dataBeforeEdit);
      this.$emit("changeEditMode");
    },
    dateUnixToString() {
      const unixDays =
        parseInt(this.servicePaymentDeadline) * this.secondsInDay;
      this.newUnixDay = convertUnixToYYYYMMDD(
        unixDays + Math.floor(Date.now() / 1000)
      ).toString();
      return this.newUnixDay;
    },
    handleAlert({ type, message }) {
      this.$store.dispatch("handleAlert", {
        type: type,
        message: message,
      });
    },

    async submitInfo() {
      if (this.$refs.form.validate()) {
        this.loading = await true;
        const quotationId = parseInt(this.$route.params.id);
        const unixDate = convertDateToUnix(
          convertUnixToYYYYMMDD(this.choosenDate),
          this.choosenTime,
          this.choosenDayTime
        );
        const props = {
          quotationRequestId: quotationId,
          status: "pending",
          validUntil: "",
          eventState: this.eventData.eventState,
          eventCity: this.eventData.eventCity,
          eventAddress: this.eventData.eventAddress,
          eventDate: unixDate,
          eventType: this.eventData.eventType,
          peopleQuantity: parseInt(this.eventData.peopleQuantity),
          clientComment: this.eventData.clientComment,
        };
        const { data, errors } = await useMutation(
          UPDATE_QUOTATION_REQUEST,
          props
        );
        if (data) {
          await this.$store.dispatch("handleAlert", {
            type: "success",
            message: "Los cambios se han guardado correctamente.",
          });
          this.$router.go(this.$router.currentRoute);
        } else if (errors) {
          await this.$store.dispatch("handleAlert", {
            type: "error",
            message: "Favor de rellenar los campos requeridos.",
          });
        }
      }
      this.loading = await false;
    },
    convertUnixToYYYYMMDD(unixTime) {
      return convertUnixToYYYYMMDD(unixTime);
    },
    convertUnixToDDMMYYYY(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    hourFormatter(unixTime) {
      return unixToHourMinuteEdit(unixTime);
    },
    timeClockFormater(unixTime) {
      return convertUnixToAmPm(unixTime);
    },
    eventStateUpdated() {
      this.eventData.eventCity = "";
    },
  },
};
</script>

<style scoped>
.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}
.bg-service-section:hover,
.bg-service-section {
  background-color: rgba(98, 37, 130, 0.001) !important;
}
</style>
